<template>
<div>
  <a-modal
    class="answer-modal"
    :width="600"
    title="反馈结果"
    :visible="visible"
    :maskClosable="false"
    @cancel="handleClose"
  >
    <template slot="footer">
      <div  class="button">
        <div @click="handleOk" class="ok">提交</div>
        <div @click="handleReset" class="cancle">重置</div>
      </div>
    </template>
   
    <div class="modal-content">
      <div class="content">
        <template v-for="(item, index) in answerList">
          <p v-if="index == 0"><b>问题类型：</b>{{ feedTypeList[item.feedType] }}</p>
          <div style="display: flex;">
            <p style="flex: 70px 0 0;"><b>{{ item.messageType == 1 ? '回复内容：' : '反馈内容：'}}</b></p>
            <div>
              <div>{{ item.feedContent }}</div>
              <div class="content-left img-box" v-if="item.fileList">
                <img :src="it" v-for="it in item.fileList" @click="handleImgBig(it)"/>
                <!-- <Previewer ref="previewer" :list="item.feedFileList" :options="options" /> -->
              </div>
              <p class="content-left">{{ item.sysCreateTime }}</p>
            </div>
          </div>
        </template>
        <a-textarea v-model:value="message" showCount placeholder="继续反馈" :maxlength="200" :autosize="{ minRows: 6, maxRows: 6 }" allowClear/>
        <div class="feed-img">
          <div class="feed-img-top">
            <span class="button-search" style="cursor: default;">上传图片</span>
            <span @click="handleUpRule"><a-icon type="question-circle" /><span >上传规则</span></span>
          </div>
          <div class="feed-img-cont">
            <a-upload
              name="files"
              list-type="picture-card"
              :action="uploadURL"
              :file-list="fileList"
              :multiple="true"
              :headers="headers"
              :before-upload="beforeUpload"
              @change="handleChange"
              @preview="handlePreview"
            >
              <div v-if="fileList.length < 6">
                <a-icon type="plus" />
                <div class="ant-upload-text">
                上传
                </div>
              </div>
            </a-upload>
            <a-modal width="100%" :visible="previewVisible" :footer="null" @cancel="previewVisible = false" class="big-img-box">
              <div style="width: 100vw;height: 100vh; display: flex;justify-content: center;align-items: center;">
                <img alt="example" :src="previewImage" style="max-width: 90vw;max-height: 90vh"/>
              </div>
            </a-modal>
          </div>
        </div>
      </div>
    </div>
  </a-modal>
  <HintOrDownModal :visible="ruleVisible" :handleClose="handleRuleClose" :title="'上传规则'" :tipsType="'tipsSeven'" :buttonText=" '我知道了' " />
</div>
</template>

<script>
import { feedbackAnswer, getFeedbackTree } from '@/api/user'
import HintOrDownModal from '@/components/HintOrDownModal.vue'
import { getToken } from '@/utils/auth'
import vuePicturePreview from 'vue-picture-preview';

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}
export default {
  components: {
    HintOrDownModal,
    Previewer: vuePicturePreview,
  },
  props: [
    'visible',
    'handleClose',
    'id'
  ],
  data() {
    return {
      headers: {
        Authorization: 'Bearer ' + getToken()
      },
      uploadURL: process.env.VUE_APP_BASE_API + 'feedback/uploadFile',
      // imgPath: process.env.VUE_APP_IMG_PATH,
      message: '',
      ruleVisible: false,
      fileList: [],
      previewVisible: false,
      previewImage: '',
      answerList: [],
      feedTypeList: {
        1: 'OCR识别',
        2: '自动标点',
        3: '自动标线',
        4: '繁转简',
        5: '体验',
        6: '交易',
        7: '其他'
      },
      options: {
        getThumbBoundsFn(index) {
          let thumbnail = document.querySelectorAll('.previewer-demo-img')[
            index
          ];
        }
      }, // 图片放大配置
    }
  },
  async mounted() {
    const res = await getFeedbackTree({
      id: this.id
    })

    this.answerList = res.data.list
  },
  methods: {
    handlePreviewCancel() {
      this.previewVisible = false;
    },
    async handlePreview(file) {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj);
      }
      this.previewImage = file.url || file.preview;
      this.previewVisible = true;
    },
    handleImgBig(img) {
      this.previewImage = img
      this.previewVisible = true;
    },
    handleChange({fileList}) {
      const newFileList = fileList.map(item => {
        if ((item.originFileObj.type === 'image/jpeg' || item.originFileObj.type === 'image/png' || item.originFileObj.type === 'image/bmp') && (item.originFileObj.size / 1024) < 200) {
          return item
        }
      }).filter(item => item)
      this.fileList = newFileList.slice(-6)
    },
    // 图片上传格式
    beforeUpload(file) {
      const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/bmp';
      if (!isJpgOrPng) {
        this.$message.error('格式不正确!');
      }
      const isLt2M = (file.size / 1024) < 200;
      if (!isLt2M) {
        this.$message.error('建议在200KB以内!');
      }
      return isJpgOrPng && isLt2M;
    },
    /** 提交 */
    async handleOk() {
      if (!this.message) {
        return this.$message.warning('请先填写反馈内容');
      }

      if (!this.fileList.length) {
        return this.$message.warning('请上传反馈文件');
      }
      await feedbackAnswer({
        parentId: this.id,
        files: this.fileList.map(item => {
          return item.response.data[0]
        }),
        message: this.message
      })
      this.$message.success('反馈成功，请耐心等待');
      this.handleClose()
    },
    /** 上传规则 */
    handleUpRule() {
      this.ruleVisible = true
    },
    handleRuleClose() {
      this.ruleVisible = false
    },
    // 重置
    handleReset() {
      this.message = ''
      this.fileList = ''
    }
  }
}
</script>

<style lang="less">
.big-img-box {
  .ant-modal-body {
    padding: 0;
  }
  .ant-modal-mask {
    background-color: #000;
  }
  .ant-modal-content {
    background-color: #000;
    width: 100%;
    height: 100%;
    overflow: hidden;
    margin-top: -100px;
  }
  .ant-modal-close {
    color: #fff;
  }
}
.answer-modal {
  .ant-modal-content {
    .ant-modal-header {
      border:  none;
      .ant-modal-title {
        width: 80px;
        height: 32px;
        border-bottom: 5px solid #6868CE;
        display: flex;
        justify-content: center;
        font-size: 16px;
        font-weight: 500;
        color: #6868CE;
      }
    }

    .ant-modal-footer {
      .button {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 46px;

        
        div {
          width: 84px;
          height: 34px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 14px;
          font-weight: 500;
          color: #6868CE;
          cursor: pointer;
        }

        .cancle {
          border: 1px solid #6868CE;
        }


        .ok {
          background: url(../../assets/beijing.png) no-repeat;
          background-size: cover;
          color: #fff;
          border: none;
          margin-right: 50px;
        }

        // div {
        //   &:nth-of-type(1):hover {
        //     border: 1px solid #6868CE;
        //     color: #6868CE;
        //   }
        //   &:nth-of-type(2):hover {
        //     opacity: 0.9;
        //   }
        // }
      }
    }

    .ant-modal-body {
      padding-top: 0;
    }

    .modal-content {
      width: 100%;
      min-height: 160px;
      max-height: 500px;
      overflow-y: auto;

      .content {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        font-size: 14px;
        font-weight: 400;
        color: #333333;
        line-height: 28px;

        .feed-img {
          margin-top: 20px;
          .feed-img-top {
            display: flex;
            align-items: center;
            span {
              margin-right: 10px;
              cursor: pointer;
              margin-left: 0;
            }
          }
          .feed-img-cont {
            height: 104px;
            background: #ECF1FD;
            margin-top: 10px;
            display: flex;
          }
          .file-box {
            display: flex;
            img {
              width: 104px;
              height: 104px;
              margin-right: 10px;
            }
          }
        }

        .img-box {
          img {
            max-width: 100px;
            max-height: 100px;
            margin: 10px 0;
            cursor: pointer;
          }
        }

        .content-left {
          margin-bottom: 10px;
          color: #999;
          font-size: 12px;
        }
      }
    }
  }
}

</style>