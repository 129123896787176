<template>
<div>
  <a-modal
    class="no-invoice-modal"
    :width="1200"
    title="未开票订单"
    :visible="visible"
    :maskClosable="false"
    @cancel="handleClose"
  >
    <template slot="footer">
      
      <div style="display: flex;justify-content: space-between;align-items: center;">
        <p>仅展示近三个月订单，且为今年订单（即不能跨年）</p>
        <div style="display: flex;align-items: center;">
          <span>已选订单：{{selectedRowKeys.length}}条 共计：{{sumTotal}}元</span>
          <div  class="button">
            <div @click="handleOk" class="ok">提交</div>
            <div @click="handleSelectClear" class="cancle">重置</div>
          </div>
        </div>
      </div>
    </template>
   
    <div class="modal-content">
      <div class="search-top">
        <div>
          区间查询：
          <a-range-picker v-model="date" format="YYYY-MM-DD" :disabled-date="disabledDate"/>
        </div>

        <div class="search-right-box">
          <span class="button-search" @click="onSearch">查询</span>
          <span class="button-primary" @click="onReset">重置</span>
        </div>
      </div>
      
      <a-table
        :columns="columns"
        :data-source="personalData.rows"
        :row-key="record => record.id"
        :pagination="{
          total: personalData.total,
          current: pageNum,
          pageSize: pageSize,
          showTotal: () => `共${personalData.total}条`,
          showSizeChanger: true,
        }"
        @change="handlePageChange"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
      >
        <template slot="type" slot-scope="text">
          {{ text == 1 ? '购买' : text == 2 ? '赠送' : text == 3 ? '兑换' : ''}}
        </template>
      </a-table>
    </div>
  </a-modal>
</div>
</template>

<script>
import { getInvoiceList, minInvoiceDate } from '@/api/user'
import dayjs from 'dayjs'

export default {
  props: [
    'visible',
    'handleClose',
    'handleCallback',
  ],
  data() {
    return {
      date: [],
      columns: [
        {
          title: '序号',
          dataIndex: 'key',
        },
        {
          title: '订单号',
          dataIndex: 'orderCode',
          ellipsis: true,
          width: 100
        },
        {
          title: '分类',
          dataIndex: 'orderType',
          scopedSlots: { customRender: 'type' },
        },
        {
          title: '川资',
          dataIndex: 'pageCount',
        },
        {
          title: '金额/元',
          dataIndex: 'payAmout',
        },
        {
          title: '额度说明',
          dataIndex: 'payDesc',
          width: 300
        },
        {
          title: '开票状态',
          dataIndex: 'invoiceStatusText',
        },
        {
          title: '时间',
          dataIndex: 'payTime',
          ellipsis: true,
          width: 190
        },
      ],
      personalData:{
        rows: [],
        total: 0
      },
      pageNum: 1,
      pageSize: 10,
      selectedRowKeys: [],
      startDate: '',
      sumTotal: 0
    }
  },
  watch: {
    selectedRowKeys (val) {
      let total = 0

      this.personalData.rows.map(item => {
        if (val.indexOf(item.id) != -1) {
          total = total + item.payAmout
        }
      })

      this.sumTotal = total
    }
  },
  async mounted() {
    this.getList()

    // const res = await minInvoiceDate({})
    // this.startDate = dayjs(res.data.minInvoiceDate).format("YYYY-MM-DD")
  },
  methods: {
    /** 提交 */
    async handleOk() {
      if (this.selectedRowKeys.length == 0) {
        return this.$message.warning('请先选择')
      }
      this.handleCallback(this.sumTotal, this.selectedRowKeys)
    },
    async getList() {
      const res = await getInvoiceList({
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        payBeginTime: this.date.length ? dayjs(this.date[0]).format("YYYY-MM-DD") : '',
        payEndTime: this.date.length ? dayjs(this.date[1]).format("YYYY-MM-DD") : ''
      })
     

      res.rows.map((item, index)=> {
        item.key = index + 1
      })

      this.personalData = res
    },
    // 翻页
    handlePageChange(e) {
      this.pageNum = e.current
      this.pageSize = e.pageSize

      this.getList()
    },
    // 搜索
    onSearch() {
      this.pageNum = 1
      this.getList()
    },
    // 重置
    onReset() {
      this.pageNum = 1
      this.date = []
      this.getList()
    },
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys;
    },
    disabledDate(current) {
      // Get the current date
      var currentDate = new Date();

      // Calculate the date three months ago
      var threeMonthsAgo = new Date();
      threeMonthsAgo.setMonth(threeMonthsAgo.getMonth() - 3);

      // Disable dates that are after the current date or before three months ago
      return current && (current > currentDate || current < threeMonthsAgo);
    },
    handleSelectClear() {
      this.selectedRowKeys = []
    }
  }
}
</script>

<style lang="less">
.no-invoice-modal {
  .ant-modal-content {
    .ant-modal-header {
      border:  none;
      .ant-modal-title {
        width: 80px;
        height: 32px;
        border-bottom: 5px solid #6868CE;
        display: flex;
        justify-content: center;
        font-size: 16px;
        font-weight: 500;
        color: #6868CE;
      }
    }

    .ant-modal-footer {
      .button {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 46px;

        
        div {
          width: 84px;
          height: 34px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 14px;
          font-weight: 500;
          color: #6868CE;
          cursor: pointer;
        }

        .cancle {
          border: 1px solid #6868CE;
        }


        .ok {
          background: url(../../assets/beijing.png) no-repeat;
          background-size: cover;
          color: #fff;
          border: none;
          margin-right: 50px;
        }
      }
    }

    .ant-modal-body {
      padding-top: 0;
    }

    .modal-content {
      width: 100%;
      min-height: 160px;
      max-height: 400px;
      overflow-y: auto;

      .search-top {
        margin-bottom: 20px;
        display: flex;
        justify-content: space-between;
      }

      .search-right-box {
        display: flex;
      }
    }
  }
}

</style>