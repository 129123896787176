<template>
  <div class="Consume">
    <div class="list">
      <div class="top">
        <div class="left">
          分类：
          <a-checkbox-group v-model="status" name="checkboxgroup" :options="statusOptions" />
          <div>
            区间查询：
            <a-range-picker v-model="date" format="YYYY-MM-DD" />
            开票状态：
            <a-select
              :getPopupContainer="
                triggerNode => {
                  return triggerNode.parentNode || document.body;
                }
              "
              v-model="invoiceStatus"
              placeholder="请选择"
              :options="invoiceStatusList"
              @change="onSearch"
            >
            </a-select>
            发票号：
            <a-input v-model="invoiceCode" placeholder="请输入" :maxLength='50'/>
          </div>
        </div>
        <div class="search-right-box">
          <span class="button-search" @click="onSearch">查询</span>
          <span class="button-primary" @click="onReset">重置</span>
        </div>
      </div>

      <a-table
        :columns="columns"
        :data-source="personalData.rows"
        :row-key="record => record.id"
        :pagination="{
          total: personalData.total,
          current: pageNum,
          pageSize: pageSize,
          showTotal: () => `共${personalData.total}条`,
          showSizeChanger: true,
        }"
        @change="handlePageChange"
        :row-selection="rowSelection"
      >
        <template slot="type" slot-scope="text">
          {{ text == 1 ? '购买' : text == 2 ? '赠送' : text == 3 ? '兑换' : ''}}
        </template>
        <template slot="payAmout" slot-scope="text">
          {{ text ? text + '元' : ''}}
        </template>
      </a-table>
    </div>
  </div>
</template>

<script>
import { getRechargeList } from '@/api/user'
import dayjs from 'dayjs';
import bus from '@/utils/bus'

export default {
  computed: {
    rowSelection() {
      return {
        onChange: (selectedRowKeys) => {
          this.selectedRowKeys = selectedRowKeys
          this.$emit('changeParams', {...this.params, ids: this.selectedRowKeys.join(',')})
        },
      };
    },
  },
  data() {
    return {
      statusOptions: [
        {
          value: 1,
          label: '购买'
        } ,
        {
          value: 2,
          label: '赠送'
        },
        {
          value: 3,
          label: '兑换'
        }
      ],
      columns: [
        {
          title: '序号',
          dataIndex: 'key',
        },
        {
          title: '订单号',
          dataIndex: 'orderCode',
        },
        {
          title: '分类',
          dataIndex: 'orderType',
          scopedSlots: { customRender: 'type' },
        },
        {
          title: '川资',
          dataIndex: 'pageCount',
        },
        {
          title: '金额',
          dataIndex: 'payAmout',
          scopedSlots: { customRender: 'payAmout' },
        },
        {
          title: '说明',
          dataIndex: 'payDesc',
        },
        {
          title: '开票状态',
          dataIndex: 'invoiceStatusText',
        },
        {
          title: '发票号',
          dataIndex: 'invoiceCode',
        },
        {
          title: '时间',
          dataIndex: 'payTime',
        },
      ],
      personalData:{
        rows: [],
        total: 0
      },
      pageNum: 1,
      pageSize: 10,
      checkedNewsList: [], // 选中的列表
      date: [],
      status: [],
      invoiceStatus: undefined,
      invoiceCode: '',
      invoiceStatusList: [
        {
          value: 1,
          label: '未开票'
        } ,
        {
          value: 2,
          label: '开票中'
        },
        {
          value: 3,
          label: '已开票'
        },
        {
          value: 4,
          label: '不可开票'
        }
      ],
      selectedRowKeys: []
    }
  },
  mounted() {
    this.getList()
    bus.$on('refreshInvoice',() => {
      this.getList()
    })
  },
  methods: {
    async getList() {
      let params = {
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        payBeginTime: this.date.length ? dayjs(this.date[0]).format("YYYY-MM-DD") : '',
        payEndTime: this.date.length ? dayjs(this.date[1]).format("YYYY-MM-DD") : '',
        orderTypes: this.status,
        invoiceCode: this.invoiceCode,
        invoiceStatus: this.invoiceStatus
      }
      const res = await getRechargeList(params)

      this.$emit('changeParams', {...params, ids: this.selectedRowKeys.join(',')})
      res.rows.map((item, index)=> {
        item.key = index + 1
      })
      this.personalData = res
    },
    // 翻页
    handlePageChange(e) {
      this.pageNum = e.current
      this.pageSize = e.pageSize

      this.getList()
    },
    // 搜索
    onSearch() {
      this.pageNum = 1
      this.getList()
    },
    // 重置
    onReset() {
      this.pageNum = 1
      this.date = []
      this.status = []
      this.status = []
      this.invoiceCode = []
      this.invoiceStatus = undefined
      this.getList()
    }
  }
};
</script>

<style lang="less">
.Consume {
  width: 100%;

  .title {
    display: flex;
    justify-content: space-between;
    & > div {
      display: flex;
    }
    .button-primary {
      height: 34px !important;
      margin-right: 0;
      color: #6868CE;
    }
  }

  .list {
    width: 100%;
    // min-height: 500px;
    background: #ffffff;
    box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    border: 1px solid #e6e6e6;
    margin-top: 10px;
    padding: 0px 26px 16px 26px;
    box-sizing: border-box;

    .top {
      width: 100%;
      margin: 10px 0;
      padding-bottom: 10px;
      border-bottom: 1px dashed #c6c4c4;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .left {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        .ant-radio-group {
          margin-left: 10px;
        }
        .ant-calendar-picker {
          margin: 0 20px 0 0;
        }
      }

      .search-right-box {
        display: flex;
        flex: 220px 0 0;
      }
    }
  }

  .ant-checkbox-group {
    margin-right: 20px;
  }

  .top {
    input, .ant-select {
      width: 120px;
      margin-right: 20px;
    }
  }

  label {
    font-weight: normal;
    color: #000;
  }
}
</style>
