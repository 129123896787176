<template>
  <div class="answer-feedback">
    <div class="list">
      <div class="top">
        <div class="right">
          <div>
            状态：
            <span
              v-for="item in statusOptions"
              :class="['status-box', {active: item.value == status}]"
              @click="handleStatus(item.value)"
            >{{ item.label }}</span>
            <a-input v-model="content" placeholder="请输入" :maxLength='30'/>
          </div>
          <div class="search-right-box">
            <span class="button-search" @click="onSearch">查询</span>
            <span class="button-primary" @click="onReset">重置</span>
          </div>
        </div>
      </div>

      <a-table
        :columns="columns"
        :data-source="personalData.rows"
        :row-key="record => record.id"
        :pagination="{
          total: personalData.total,
          current: pageNum,
          pageSize: pageSize,
          showTotal: () => `共${personalData.total}条`,
          showSizeChanger: true,
        }"
        @change="handlePageChange"
      >
        <template slot="feedType" slot-scope="text">
          {{ typeObj[text] }}
        </template>
      
        <template slot="status" slot-scope="text">
          {{ text == 0 ? '未读' : text == 1 ? '已读' :  text == 2 ? '暂无回复' : ''}}
        </template>
        <template slot="action" slot-scope="text, record">
          <span class="see-text" @click="handleAnswerShow(record)">
            <a-icon type="file-search" />查看
          </span>
        </template>
      </a-table>
    </div>

    <AnswerModal
      v-if="answerVisible"
      :visible="answerVisible"
      :handleClose="handleAnswerClose"
      :id="answerId"
    />
  </div>
</template>

<script>
import { getListAnswer } from '@/api/user'
import AnswerModal from './AnswerModal.vue';
import bus from '@/utils/bus'

export default {
  components: { 
    AnswerModal
  },
  data() {
    return {
      statusOptions: [
        {
          value: 0,
          label: '未读'
        } ,
        {
          value: 1,
          label: '已读'
        },
        {
          value: 2,
          label: '暂无回复'
        }
      ],
      columns: [
        {
          title: '序号',
          dataIndex: 'key',
        },
        {
          title: '类型',
          dataIndex: 'feedType',
          scopedSlots: { customRender: 'feedType' },
        },
        {
          title: '反馈内容',
          dataIndex: 'feedContent',
          ellipsis: true,
        },
        {
          title: '状态',
          dataIndex: 'status',
          scopedSlots: { customRender: 'status' },
        },
        {
          title: '时间',
          dataIndex: 'sysCreateTime',
        },
        {
          title: '操作',
          dataIndex: 'action',
          scopedSlots: { customRender: 'action' },
        },
      ],
      personalData:{
        rows: [],
        total: 0
      },
      pageNum: 1,
      pageSize: 10,
      status: 0,
      content: '',
      answerVisible: false,
      typeObj: {
        1: 'OCR识别',
        2: '自动标点',
        3: '自动标线',
        4: '繁转简',
        5: '体验',
        6: '交易',
        7: '其他'
      },
      answerId: ''
    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    async getList() {
      const res = await getListAnswer({
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        status: this.status,
        content: this.content
      })

      res.rows.map((item, index)=> {
        item.key = index + 1
      })

      this.personalData = res

      bus.$emit('feedBackAnswer')
    },
    // 翻页
    handlePageChange(e) {
      this.pageNum = e.current
      this.pageSize = e.pageSize

      this.getList()
    },
    // 搜索
    onSearch() {
      this.pageNum = 1
      this.getList()
    },
    // 重置
    onReset() {
      this.pageNum = 1
      this.status = ''
      this.content = ''
      this.getList()
    },
    handleStatus(status) {
      this.status = status
      this.onSearch()
    },
    handleAnswerClose() {
      this.answerVisible = false
      this.getList()
    },
    handleAnswerShow(data) {
      this.answerVisible = true
      this.answerId = data.id
    }
  }
};
</script>

<style lang="less">
.answer-feedback {
  width: 100%;

  .title {
    display: flex;
    justify-content: space-between;
    & > div {
      display: flex;
    }
    .button-primary {
      height: 34px !important;
      margin-right: 0;
      color: #6868CE;
    }
  }

  .list {
    width: 100%;
    // min-height: 500px;
    background: #ffffff;
    box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    border: 1px solid #e6e6e6;
    margin-top: 10px;
    padding: 0px 26px 16px 26px;
    box-sizing: border-box;

    .top {
      width: 100%;
      margin: 10px 0;
      padding-bottom: 10px;
      border-bottom: 1px dashed #c6c4c4;

      .right {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .ant-calendar-picker {
          margin: 0 20px 0 0;
        }

        .search-right-box {
          display: flex;
        }
      }
    }
  }

  .status-box {
    padding: 4px 14px;
    border: 1px solid #ccc;
    cursor: pointer;
    &.active {
      border: 1px solid #6868CE !important;
    }
  }

  .status-box:nth-of-type(1) {
    border-radius: 4px 0 0 4px;
  }

  .status-box:nth-of-type(3) {
    border-radius: 0 4px 4px 0;
  }

  input {
    width: 300px;
    margin-left: 20px;
  }

  .see-text {
    color: #6868CE;
    cursor: pointer;
    display: flex;
    align-items: center;
    i {
      margin-right: 4px;
    }
  }
}
</style>
