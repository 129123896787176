<template>
  <div class="my-organization">
    <div class="list">
      <div class="top">
        <div class="right">
          <div>
            机构名称：
            <a-select
              :getPopupContainer="
                triggerNode => {
                  return triggerNode.parentNode || document.body;
                }
              "
              v-model="institutionName"
              placeholder="请选择"
              :options="joinSelectOptions"
              @change="onSearch"
              show-search
            >
            </a-select>
      
            区间查询：
            <a-range-picker v-model="date" format="YYYY-MM-DD"/>
          </div>
          <div class="search-right-box">
            <span class="button-search" @click="onSearch">查询</span>
            <span class="button-primary" @click="onReset">重置</span>
          </div>
        </div>
      </div>

      <a-table
        :columns="columns"
        :data-source="personalData.rows"
        :row-key="record => record.id"
        :pagination="{
          total: personalData.total,
          current: pageNum,
          pageSize: pageSize,
          showTotal: () => `共${personalData.total}条`,
          showSizeChanger: true,
        }"
        @change="handlePageChange"
      >
        <template slot="authStatus" slot-scope="text">
          {{ text == 1 ? '审核通过' : text == 2 ? '审核不通过' : text == 0 ? '待审核' : ''}}
        </template>
        <template slot="action" slot-scope="text, record">
          <span
            v-if="record.defaultInstitution == 0 && record.runStatus == 1"
            class="see-text"
            @click="handleDefault(record)"
          >
            <a-icon type="font-colors" />设为默认
          </span>
          <span v-if="record.defaultInstitution == 1">默认机构</span>
        </template>
      </a-table>
    </div>
  </div>
</template>

<script>
import { getJoinList, getJoinSelect, updateDefault } from '@/api/institution'
import dayjs from 'dayjs';

export default {
  data() {
    return {
      columns: [
        {
          title: '序号',
          dataIndex: 'key',
        },
        {
          title: '机构名称',
          dataIndex: 'institutionName',
        },
        {
          title: '申请状态',
          dataIndex: 'authStatus',
          scopedSlots: { customRender: 'authStatus' },
        },
        {
          title: '意见',
          dataIndex: 'authRemark',
        },
        {
          title: '申请时间',
          dataIndex: 'sysCreateTime',
        },
        {
          title: '审核时间',
          dataIndex: 'authDate',
        },
        {
          title: '到期时间',
          dataIndex: 'expireDate',
        },
        {
          title: '操作',
          dataIndex: 'action',
          scopedSlots: { customRender: 'action' },
        },
      ],
      personalData:{
        rows: [],
        total: 0
      },
      pageNum: 1,
      pageSize: 10,
      date: [],
      institutionName: undefined,
      joinSelectOptions: []
    }
  },
  async mounted() {
    this.getList()
    const res = await getJoinSelect({})
    let newList = []
    res.data.map(item => {
      newList.push( {
        label: item,
        value: item
      })
    })

    this.joinSelectOptions = newList
  },
  methods: {
    async getList() {
      const res = await getJoinList({
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        institutionName: this.institutionName,
        startDate: this.date.length ? dayjs(this.date[0]).format("YYYY-MM-DD") : undefined,
        endDate: this.date.length ? dayjs(this.date[1]).format("YYYY-MM-DD") : undefined,
      })

      res.rows.map((item, index)=> {
        item.key = index + 1
      })

      this.personalData = res
    },
    // 翻页
    handlePageChange(e) {
      this.pageNum = e.current
      this.pageSize = e.pageSize

      this.getList()
    },
    // 搜索
    onSearch() {
      this.pageNum = 1
      this.getList()
    },
    // 重置
    onReset() {
      this.pageNum = 1
      this.date = []
      this.institutionName = undefined
      this.getList()
    },
    // 设置为默认机构
    async handleDefault(data) {
      await updateDefault({
        id: data.id
      })

      this.$message.success('设置成功');
      this.getList()
    }
  }
};
</script>

<style lang="less">
.my-organization {
  width: 100%;

  .title {
    display: flex;
    justify-content: space-between;
    & > div {
      display: flex;
    }
    .button-primary {
      height: 34px !important;
      margin-right: 0;
      color: #6868CE;
    }
  }

  .list {
    width: 100%;
    // min-height: 500px;
    background: #ffffff;
    box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    border: 1px solid #e6e6e6;
    margin-top: 10px;
    padding: 0px 26px 16px 26px;
    box-sizing: border-box;

    .top {
      width: 100%;
      margin: 10px 0;
      padding-bottom: 10px;
      border-bottom: 1px dashed #c6c4c4;

      .left {
        display: flex;
        align-items: center;
        margin-bottom: 16px;
        .ant-radio-group {
          margin-left: 10px;
        }
      }

      .right {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .ant-calendar-picker {
          margin: 0 20px 0 0;
        }

        .search-right-box {
          display: flex;
        }
      }
    }
  }

  .ant-checkbox-group {
    margin-left: 20px;
  }

  .top {
    input {
      width: 140px;
    } 
    .ant-select {
      width: 300px;
      margin-right: 20px;
    }
  }

  .see-text {
    color: #6868CE;
    cursor: pointer;
    display: flex;
    align-items: center;
    i {
      margin-right: 4px;
    }
  }
}
</style>
