<template>
  <div class="personal">
    <div class="nav-title">
      <i></i>首页— 个人中心
    </div>
    <div class="personal-content">
      <div>
        <div class="left-nav">
          <img :src="`${imgPath}${userInfo.avatarCenter}`" class="head"/>
          <div class="name" :title="userInfo.nikeName">Hi～{{userInfo.nikeName}}</div>
          <div class="nav-list">
            <div 
              v-for="(item, key) in navList"
              :key="key"
              @click="onChangeNav(key)"
              :class="[{active: navTypeChecked == key}]"
            >
              <a-icon v-if="key == 'myOrganization'" type="menu-fold" class="people-left-icon"/>
              <img v-else :src="require(`@/assets/icon-personal-${key}.png`)" />
            {{ item }}
            </div>
          </div>
        </div>

        <div class="chat-box">
          <img src="@/assets/chat.jpg" />
          <p>如您有任何问题，也可扫码添加小古微信，随时联系我们。</p>
        </div>
      </div>
      <div class="content">
        <div class="title">
          <div style="display: flex;align-items: center;">
            <span :class="[{'active': !feedbackFlag}]" @click="feedbackFlag = false">
              {{navList[navTypeChecked] }}
              {{navTypeChecked == 'consume' || navTypeChecked == 'usagelog' ? '：' : ''}}
            </span>
            <span :class="['circle-box', {active: feedbackFlag}]" v-if="navTypeChecked == 'feedback'" @click="feedbackFlag = true">
              <i v-if="feedBackCount"></i>
              查看回复
            </span>
            <i v-if="navTypeChecked == 'consume'">累计获取额度{{sumPageCount.sumPageCountByWen}}文 {{ sumPageCount.sumPageCountByYe }}页</i>
            <i v-if="navTypeChecked == 'usagelog'">累计已处理{{ textPage }}页 {{textCount}}字</i>
          </div>
          
          <div style="display: flex;">
            <template v-if="navTypeChecked == 'consume'">
              <span class="button-primary" @click="handleDown" >导出</span>
              <span class="button-primary" @click="noInvoiceFlag = true">申请开票</span>
            </template>
            <span class="button-primary" @click="onBack">返回</span>
          </div>
        </div>
        <Consume v-if="navTypeChecked == 'consume'" @changeParams="getConsumeParams"/>

        <UsageLog  v-if="navTypeChecked == 'usagelog'"/>

        <MyOrganization  v-if="navTypeChecked == 'myOrganization'"/>

        <FeedBack v-if="navTypeChecked == 'feedback' && !feedbackFlag"/>

        <AnswerFeedBack v-if="feedbackFlag" />

        <NoInvoiceModal v-if="noInvoiceFlag" :visible="noInvoiceFlag" 
          :handleClose="handleInvoiceHide" :handleCallback="handleInvoiceSubmit"/>

        <ApplyInvoiceModal
          v-if="applyInvoiceFlag"
          :visible="applyInvoiceFlag" 
          :handleClose="handleApplyInvoiceHide"
          :handleOk="handleApplyInvoiceSubmit"
          :sumTotal="sumTotal"
          :selectedRowKeys="selectedRowKeys"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Cookies from 'js-cookie'
import Consume from './Consume.vue'
import FeedBack from './FeedBack.vue'
import MyOrganization from './MyOrganization.vue'
import UsageLog from './UsageLog.vue'
import AnswerFeedBack from './AnswerFeedBack.vue'
import NoInvoiceModal from './NoInvoiceModal.vue'
import { getSumPageNum, getTimerCount} from '@/api/user'
import bus from '@/utils/bus'
import ApplyInvoiceModal from '@/components/ApplyInvoiceModal'
import { rechargeExport } from '@/api/download'
import { exportByUrl } from '@/utils/download'

export default {
  components: { Consume, FeedBack, MyOrganization, UsageLog, 
    AnswerFeedBack, NoInvoiceModal, ApplyInvoiceModal },
  data() {
    return {
      navList: {
        'news': '消息中心',
        'consume': '充值记录',
        'usagelog': '使用记录',
        'myOrganization': '我的机构',
        'feedback': '意见反馈'
      },
      navTypeChecked: 'consume',
      userInfo: {},
      imgPath: process.env.VUE_APP_IMG_PATH,
      feedbackFlag: false,
      sumPageCount: '',
      textCount: '',
      textPage: '',
      noInvoiceFlag: false,
      applyInvoiceFlag: false,
      feedBackCount: 0,
      consumeParams: {},
      loadingExport: false
    }
  },
  watch: {
    $route() {
      this.$router.go(0)
    },
  },
  async mounted() {
    this.navTypeChecked = this.$route.query.type || 'consume'

    this.userInfo = JSON.parse(Cookies.get('userInfo'))
    this.navList['consume'] = `充值记录`
    
    this.getNewsFeedBackCount()

    // 充值记录-累积获得额度
    const result = await getSumPageNum() 
    this.sumPageCount = result.data

    // 使用记录头部数字
    bus.$on('getTextPage', (data) => {
      this.textCount = data.textCount
      this.textPage = data.textPage
    })

    bus.$on('newsCountRefresh', async() => {
      this.userInfo = JSON.parse(Cookies.get('userInfo'))
    })

    bus.$on('feedBackAnswer',() => {
      this.getNewsFeedBackCount()
    })

    bus.$on('feedBackCount', ({feedBackCount}) => {
      this.feedBackCount = feedBackCount
    })
  },
  beforeDestroy() {
    clearInterval(this.timer)
  },
  methods: {
    async getNewsFeedBackCount() {
      const ret = await getTimerCount()
      this.navList['news'] = `消息中心（${ret.data.msgCount}）`
      this.feedBackCount = ret.data.feedBackCount
    },
    onChangeNav(type) {
      if (type == 'news') {
        return this.$router.push('/news')
      } else {
        this.feedbackFlag = false
        this.navTypeChecked = type
      }
    },
    onBack() {
      this.$router.go(-1)
    },
    // 充值记录导出
    async handleDown() {
      const res = await rechargeExport(this.consumeParams)
      this.$message.success('文件正在下载请耐心等候')
      exportByUrl({
        url: res.data.url,
        target: '_self',
      })
    },
    handleInvoiceHide() {
      this.noInvoiceFlag = false
    },
    handleApplyInvoiceHide() {
      this.applyInvoiceFlag = false
    },
    handleApplyInvoiceSubmit() {
      this.applyInvoiceFlag = false
      this.noInvoiceFlag = false

      bus.$emit('refreshInvoice')
    },
    handleInvoiceSubmit(sumTotal, selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys
      this.sumTotal = sumTotal
      this.applyInvoiceFlag = true
    },
    getConsumeParams(data) {
      this.consumeParams = data
    }
  }
};
</script>

<style lang="less">
.personal {
  min-height: 787px;
  background: #edf0f2;
  padding: 46px 0px 100px;
  box-sizing: border-box;

  .title {
    display: flex;
    justify-content: space-between;
    .button-primary {
      height: 34px !important;
      margin-right: 0;
      color: #6868CE;
    }

    i {
      font-style: normal;
      font-size: 16px;
      font-weight: 500;
      color: #4A4A4A;
    }
  }
 
  .personal-content {
    display: flex;
    justify-content: center;
    margin: 0 auto;
    padding: 0 51px;
  }

  .left-nav {
    flex: 198px 0 0;
    width: 198px;
    height: 407px;
    background: #FFFFFF;
    box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    border: 1px solid #E6E6E6;
    margin-right: 16px;
    .head {
      width: 90px;
      height: 90px;
      margin: 22px 0 17px;
    }
    .name {
      font-size: 16px;
      font-weight: 500;
      color: #6868CE;
      line-height: 22px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      padding: 0 10px;
    }
    .nav-list {
      margin-top: 15px;
      div {
        margin: 0 19px;
        font-size: 14px;
        font-weight: 500;
        color: #4A4A4A;
        text-align: left;
        line-height: 28px;
        padding: 8px 0;
        border-bottom: 1px dashed #C6C4C4;
        cursor: pointer;
        
        img {
          width: 20px;
          height: 20px;
          margin-right: 12px;
        }

        &:last-child {
          border-bottom: none;
        }
        &.active {
          color: #6868CE;
        }
      }
    }
  }

  .content {
    flex: 1;
  }

  .chat-box {
    width: 190px;
    flex: 190px 0 0;
    padding: 4px;
    font-size: 14px;
    margin-top: 10px;
    img {
      width: 190px;
      border-radius: 8px;
      margin-bottom: 10px;
    }
    p {
      padding: 0 4px;
      font-size: 12px;
      color: #606266
    }
  }

  .people-left-icon {
    color: #6868CE;
    font-size: 20px;
    margin-right: 12px;
    font-weight: bold;
  }

  .circle-box {
    position: relative;
    i {
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background: red;
      color: #fff;
      position: absolute;
      top: 8px;
      right: -8px;
      font-style: normal;
    }
  }
}
</style>
