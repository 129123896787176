<template>
  <div class="feed-back">
    <div style="display: flex; margin-left: -11px;">
      <span class="required">*</span>
      <a-select
        ref="select"
        v-model="feedObj.feedType"
        style="width: 190px"
        placeholder="请选择问题类型"
        :options="feedTypeList"
      />
    </div>

    <div class="phone-input">
      <a-input v-model="feedObj.userContact" placeholder="请填写联系方式" :maxLength='30'/>
    </div>

    <div class="feed-img">
      <div class="feed-img-top">
        <span class="button-search" style="cursor: default;">上传图片</span>
        <span @click="handleUpRule"><a-icon type="question-circle" /><span >上传规则</span></span>
      </div>
      <div class="feed-img-cont">
        <a-upload
          name="files"
          list-type="picture-card"
          :action="uploadURL"
          :file-list="feedObj.fileList"
          :multiple="true"
          :headers="headers"
          :before-upload="beforeUpload"
          @change="handleChange"
          @preview="handlePreview"
        >
          <div v-if="feedObj.fileList.length < 6">
            <a-icon type="plus" />
            <div class="ant-upload-text">
             上传
            </div>
          </div>
        </a-upload>
        <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel">
          <img alt="example" style="width: 100%;margin: 50px auto 0;display: block;" :src="previewImage" />
        </a-modal>
      </div>
    </div>

    <div class="feed-content">
      <span class="required">*</span>
      <a-textarea
        v-model="feedObj.feedContent"
        :placeholder="inputTips"
        :autosize="{ minRows: 6, maxRows: 6 }"
        allowClear
        :maxLength="200"
      />
    </div>
    <div class="feed-btn">
      <span class="button-search" @click="onSubmit">提交</span>
      <span class="button-primary" @click="onReset">重置</span>
    </div>
    <HintOrDownModal :visible="visible" :handleClose="handleDownClose" :title="'上传规则'" :tipsType="'tipsSeven'" :buttonText=" '我知道了' " />
  </div>
</template>

<script>
import { feedback } from '@/api/user'
import { getMessageAlert } from '@/api/announce'
import HintOrDownModal from '@/components/HintOrDownModal.vue'
import { message } from 'ant-design-vue';
import { getToken } from '@/utils/auth'

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}

export default {
  components: { HintOrDownModal },
  data() {
    return {
      headers: {
        Authorization: 'Bearer ' + getToken()
      },
      uploadURL: process.env.VUE_APP_BASE_API + 'feedback/uploadFile',
      imgPath: process.env.VUE_APP_IMG_PATH,
      feedTypeList: [
        {
          value: 1,
          label: 'OCR识别'
        },
        {
          value: 2,
          label: '自动标点'
        },
        {
          value: 3,
          label: '自动标线'
        },
        {
          value: 4,
          label: '繁转简'
        },
        {
          value: 5,
          label: '体验'
        },
        {
          value: 6,
          label: '交易'
        },
        {
          value: 7,
          label: '其他'
        }
      ],
      feedObj: {
        feedType: undefined,
        userContact: '',
        feedContent: '',
        fileList: []
      },
      loading: false,
      visible: false,
      previewVisible: false,
      previewImage: '',
      inputTips: ''
    }
  },
  async created() {
    const res = await getMessageAlert()

    this.inputTips = res.data
  },
  methods: {
    handleCancel() {
      this.previewVisible = false;
    },
    async handlePreview(file) {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj);
      }
      this.previewImage = file.url || file.preview;
      this.previewVisible = true;
    },
    handleChange({fileList}) {
      const newFileList = fileList.map(item => {
        if ((item.originFileObj.type === 'image/jpeg' || item.originFileObj.type === 'image/png' || item.originFileObj.type === 'image/bmp') && (item.originFileObj.size / 1024) < 200) {
          return item
        }
      }).filter(item => item)
      this.feedObj.fileList = newFileList.slice(-6)
    },
    // 图片上传格式
    beforeUpload(file) {
      const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/bmp';
      if (!isJpgOrPng) {
        this.$message.error('格式不正确!');
      }
      const isLt2M = (file.size / 1024) < 200;
      if (!isLt2M) {
        this.$message.error('建议在200KB以内!');
      }
      return isJpgOrPng && isLt2M;
    },
    async onSubmit() {
      if (!this.feedObj.feedType) {
        message.warning('问题类型为必填项');
      } else if (!this.feedObj.feedContent) {
        message.warning('反馈内容为必填项');
      } else {
        try {
          await feedback({
            ...this.feedObj,
            fileList: this.feedObj.fileList.map(item => {
              return item.response.data[0]
            })
          })
          message.success('反馈成功！');
          this.feedObj = {
            feedType: undefined,
            userContact: '',
            feedContent: '',
            fileList: []
          }
        } catch (error) {}
      }
    },
    // 清空内容
    onReset() {
      this.feedObj = {
        feedType: undefined,
        userContact: '',
        feedContent: '',
        fileList: []
      }
    },
    /** 上传规则 */
    handleUpRule() {
      this.visible = true
    },

    handleDownClose() {
      this.visible = false
    }
  }
};
</script>

<style lang="less">
.feed-back {
  width: 100%;
  min-height: 500px;
  background: #ffffff;
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  border: 1px solid #e6e6e6;
  margin-top: 10px;
  padding: 15px 20px 16px 28px;
  box-sizing: border-box;
  text-align: left;

  .required {
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    margin-right: 5px;
    color: rgb(245, 34, 45);
    font-size: 18px;
    
  }

  .phone-input {
    .ant-input {
      margin: 12px 0;
      width: 100%;
      height: 34px;
      background: #ECF1FD;
      outline: none;
    }
  }

  .feed-btn {
    display: flex;
    justify-content: flex-end;
    padding-top: 19px;
    border-top: 1px dashed #C6C4C4;
  }

  .feed-content {
    font-size: 14px;
    font-weight: 400;
    color: #9B9B9B;
    line-height: 28px;
    margin: 10px 0 19px;
  }

  .feed-img {
    .feed-img-top {
      display: flex;
      align-items: center;
      span {
        margin-right: 10px;
        cursor: pointer;
        margin-left: 0;
      }
    }
    .feed-img-cont {
      height: 104px;
      background: #ECF1FD;
      margin-top: 10px;
      display: flex;
    }
    .file-box {
      display: flex;
      img {
        width: 104px;
        height: 104px;
        margin-right: 10px;
      }
    }
  }
}
</style>
