<template>
  <div class="usage-log">
    <div class="list">
      <div class="top">
        <div class="right">
          <div>
            使用功能：
            <a-select
              :getPopupContainer="
                triggerNode => {
                  return triggerNode.parentNode || document.body;
                }
              "
              v-model="titleType"
              placeholder="请选择"
              :options="titleTypeList"
              @change="onSearch"
            >
            </a-select>
            <a-input v-model="comBookNameOrDetail" placeholder="请输入图书名称" :maxLength='30' style="margin: 0 20px;"/>
            区间查询：
            <a-range-picker v-model="date" format="YYYY-MM-DD"/>
          </div>
          <div class="search-right-box">
            <span class="button-search" @click="onSearch">查询</span>
            <span class="button-primary" @click="onReset">重置</span>
          </div>
        </div>
      </div>

      <a-table
        :columns="columns"
        :data-source="personalData.rows"
        :row-key="record => record.id"
        :pagination="{
          total: personalData.total,
          current: pageNum,
          pageSize: pageSize,
          showTotal: () => `共${personalData.total}条`,
          showSizeChanger: true,
        }"
        @change="handlePageChange"
      >
        <template slot="bookName" slot-scope="text, record">
          {{ text || record.description.split('》')[0].split('《')[1] }}
        </template>
     
        <template slot="consumeType" slot-scope="text">
          {{ consumeTypeObj[text] }}
        </template>
        <template slot="consumeAll" slot-scope="text, record">
          {{ text }} {{ updateEndData < new Date(record.sysCreateTime).getTime() ? '文' : '页'}}
        </template>
      </a-table>
    </div>
  </div>
</template>

<script>
import { getConsumeList, getConsumeFunctions } from '@/api/user'
import dayjs from 'dayjs';
import bus from '@/utils/bus'
import { consumeTypeObj } from '@/utils/common'

export default {
  components: { },
  data() {
    return {
      columns: [
        {
          title: '序号',
          dataIndex: 'key',
        },
        {
          title: '流水号',
          dataIndex: 'serialCode',
        },
        {
          title: '图书名称',
          dataIndex: 'bookName',
          scopedSlots: { customRender: 'bookName' },
        },
        {
          title: '使用功能',
          dataIndex: 'consumeType',
          scopedSlots: { customRender: 'consumeType' },
        },
        {
          title: '川资',
          dataIndex: 'consumeAll',
          scopedSlots: { customRender: 'consumeAll' },
        },
        {
          title: '说明',
          dataIndex: 'description',
        },
        {
          title: '时间',
          dataIndex: 'sysCreateTime',
        },
      ],
      personalData:{
        rows: [],
        total: 0
      },
      pageNum: 1,
      pageSize: 10,
      date: [],
      titleTypeList: [],
      titleType: undefined,
      comBookNameOrDetail: '',
      consumeTypeObj: consumeTypeObj,
      updateEndData: ''
    }
  },
  async mounted() {
    this.getList()

    const res = await getConsumeFunctions()

    this.titleTypeList = res.data

    this.updateEndData = new Date('2023-11-16 16:00:00').getTime()
  },
  methods: {
    async getList() {
      const res = await getConsumeList({
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        beginTime: this.date.length ? dayjs(this.date[0]).format("YYYY-MM-DD") : '',
        endTime: this.date.length ? dayjs(this.date[1]).format("YYYY-MM-DD") : '',
        titleType: this.titleType,
        comBookNameOrDetail: this.comBookNameOrDetail,
      })

      bus.$emit('getTextPage', {
        textCount: res.data.word,
        textPage: res.data.page
      })

      res.data.rows.map((item, index)=> {
        item.key = index + 1
      })

      this.personalData = res.data
    },
    // 翻页
    handlePageChange(e) {
      this.pageNum = e.current
      this.pageSize = e.pageSize

      this.getList()
    },
    // 搜索
    onSearch() {
      this.pageNum = 1
      this.getList()
    },
    // 重置
    onReset() {
      this.pageNum = 1
      this.date = []
      this.titleType = undefined
      this.comBookNameOrDetail = ''
      this.getList()
    }
  }
};
</script>

<style lang="less">
.usage-log {
  width: 100%;

  .title {
    display: flex;
    justify-content: space-between;
    & > div {
      display: flex;
    }
    .button-primary {
      height: 34px !important;
      margin-right: 0;
      color: #6868CE;
    }
  }

  .list {
    width: 100%;
    // min-height: 500px;
    background: #ffffff;
    box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    border: 1px solid #e6e6e6;
    margin-top: 10px;
    padding: 0px 26px 16px 26px;
    box-sizing: border-box;

    .top {
      width: 100%;
      margin: 10px 0;
      padding-bottom: 10px;
      border-bottom: 1px dashed #c6c4c4;

      .left {
        display: flex;
        align-items: center;
        margin-bottom: 16px;
        .ant-radio-group {
          margin-left: 10px;
        }
      }

      .right {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .ant-calendar-picker {
          margin: 0 20px 0 0;
        }

        .search-right-box {
          display: flex;
        }
      }
    }
  }

  .ant-checkbox-group {
    margin-left: 20px;
  }

  .top {
    input, .ant-select {
      width: 140px;
    }
  }
}
</style>
