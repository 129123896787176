<template>
<div>
  <a-modal
    class="apply-institution-add"
    :width="440"
    title="申请开票"
    :visible="visible"
    @cancel="handleClose"
  >
    <template slot="footer">
      <div class="button">
        <div @click="handleClose">返回</div>
        <div  @click="handleSubmit" html-type="submit">开票</div>
      </div>
    </template>
    <div class="form-content">
      <h3>待开票总价：¥{{ sumTotal }}</h3>
      <a-form :form="form" :layout="formLayout" :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }">
        <a-form-item label="发票性质：">
          <a-radio-group
            v-decorator="['invoiceProperties', { rules: [{ required: true, message: '请选择' }] }]"
            @change="invoiceType"            
          >
            <a-radio :value="1">
              增值税普通发票
            </a-radio>
            <a-radio :value="2">
              增值税专用发票
            </a-radio>
          </a-radio-group>
        </a-form-item>
        <a-form-item label="投递方式：">
          <a-radio-group
            v-decorator="['deliveryMethods', { rules: [{ required: true, message: '请选择' }] }]"
            @change="deliveryType"   
            :disabled="delivery"
          >
            <a-radio :value="1">
              电子发票
            </a-radio>
            <a-radio :value="2">
              纸质发票
            </a-radio>
          </a-radio-group>
        </a-form-item>
        <a-form-item label="发票类型：">
          <a-radio-group @change="changeInvoiceType" v-decorator="['invoiceType', { rules: [{ required: true, message: '请选择' }] }]">
            <a-radio :value="1">
              个人
            </a-radio>
            <a-radio :value="2">
              非个人
            </a-radio>
          </a-radio-group>
        </a-form-item>
        <a-form-item label="发票内容：" style="display: flex;align-items: center;">
          <div style="display: flex;align-items: center;">
            <a-radio-group
              @change="changeInvoiceContent"
              v-decorator="['invoiceContent', { rules: [{ required: true, message: '请选择' }] }]"
            >
              <a-radio :value="1">
                信息技术服务
              </a-radio>
              <a-radio :value="2">
                其他
              </a-radio>
            </a-radio-group>
            <a-form-item style="margin-top: 10px;" class="other-box">
              <a-input
                v-if="otherFlag"
                style="width: 80px;"
                v-decorator="['invoiceContentDetails', { rules: [{ required: true, message: '请输入' }] }]"
                placeholder="请输入"
                :maxLength="20"
              />
            </a-form-item>
          </div>
        </a-form-item>
        <a-form-item label="抬头名称：" >
          <a-input
            v-decorator="['invoiceTitleName', { rules: [{ required: true, message: '请输入' }] }]"
            placeholder="请输入"
            :maxLength="50"
          />
        </a-form-item>
        <a-form-item v-if="taxpayerCodeFlag" label="纳税人识别号：" >
          <a-input
            v-decorator="['taxpayerCode', { rules: [{ required: true, message: '请输入' }] }]"
            placeholder="请输入"
            :maxLength="50"
          />
        </a-form-item>
        <a-form-item label="收件人姓名：" >
          <a-input
            v-decorator="['receiverFullName', { rules: [{ required: true, message: '请输入' }] }]"
            placeholder="请输入"
            :maxLength="50"
          />
        </a-form-item>
        <a-form-item label="收件人电话：" >
          <a-input
            v-decorator="['receiverPhone', { rules: [{ required: true, message: '请输入' }] }]"
            placeholder="请输入"
            :maxLength="50"
          />
        </a-form-item>
        <a-form-item label="收件人邮箱：" >
          <a-input
            v-decorator="['receiverEmail', { rules: [{ required: true, message: '请输入' }] }]"
            placeholder="请输入"
            :maxLength="50"
          />
        </a-form-item>
        <a-form-item label="收件人地址：" >
          <a-input
            v-decorator="['receiverAddress']"
            placeholder="请输入"
            :maxLength="50"
          />
        </a-form-item>
        <a-form-item label="备注：" >
          <a-input
            v-decorator="['invoiceRemark']"
            placeholder="请输入"
            :maxLength="50"
          />
        </a-form-item>
      </a-form>

      <!-- <div class="tips-box">
        <a-icon type="exclamation-circle" theme="filled" style="color: orange"/>
        <div>默认开具电子发票，请如实填写手机号码和电子邮箱，如需纸质发票请备注。7个工作日内开票并发送电子邮件或邮寄。</div>
      </div> -->
    </div>
  </a-modal>
</div>
</template>

<script>
import { getInvoiceTemplate, saveInvoice } from '@/api/user'

export default {
  props: ['visible', 'handleClose', 'sumTotal', 'selectedRowKeys', 'handleOk'],
  data() {
    return {
      form: this.$form.createForm(this, { name: 'coordinated' }),
      formLayout: 'horizontal',
      checkedOne: false,
      delivery: false,
      addressee: false,
      otherFlag: false,
      taxpayerCodeFlag: false // 纳税人识别号是否显示
    }
  },
  async mounted() {
    const res = await getInvoiceTemplate({})

    if (res.data && res.data.invoiceProperties) {
     
      const { invoiceProperties = 1, deliveryMethods = 1 } = res.data || {}
      if (invoiceProperties === 2 || deliveryMethods === 2) {
        this.addressee = true;
        this.delivery = true;
      }

      // 其他后面输入框显示
      if (res.data.invoiceContent == 2) {
        this.otherFlag = true
      } else {
        this.otherFlag = false
      }

      // 纳税人识别号
      if (res.data.invoiceType == 2) {
        this.taxpayerCodeFlag = true
      } else {
        this.taxpayerCodeFlag = false
      }
      this.$nextTick(() => {
        this.form.setFieldsValue({
          ...res.data
        })
        this.$message.warn('已为您自动读取上次填写内容，请核对！')
      })
      
    }
  },
  methods: {
    /** 确定 */
    handleSubmit() {
      this.form.validateFields(async (err, values) => {
        if (err) {
          console.log('Received values of form: ', values);
        } else {
          await saveInvoice({
            ...values,
            invoicePrice: this.sumTotal,
            orderIds: this.selectedRowKeys
          })

          this.$message.success('提交成功')
          this.handleOk()
        }
      });
    },
    /** 发票性质 */
    invoiceType(e) {
      const { value = 1 } = e?.target
      if (value === 2) {
        this.delivery = true;
        this.addressee = true;
        this.form.setFieldsValue({deliveryMethods: 2});
      } else {
        this.delivery = false;
        this.addressee = false;
        this.form.setFieldsValue({deliveryMethods: 1});
      }
    },
    /** 投递方式 */
    deliveryType(e) {
      const { value = 1 } = e?.target
      if (value === 2) {
        this.addressee = true;
      } else {
        this.addressee = false;
      }
    },
    // 发票类型
    changeInvoiceContent(e) {
      const value = e?.target.value
      if (value == 2) {
        this.otherFlag = true
      } else {
        this.otherFlag = false
        this.form.setFieldsValue({
          invoiceContentDetails: ''
        })
      }
    },
    changeInvoiceType(e) {
      const { value = 1 } = e?.target
      if (value === 2) {
        this.taxpayerCodeFlag = true;
      } else {
        this.taxpayerCodeFlag = false;
      }
    }
  },
}
</script>

<style lang="less">
.apply-institution-add {
  .ant-modal-content {
    .ant-modal-header {
      border:  none;
      .ant-modal-title {
        width: 140px;
        height: 32px;
        border-bottom: 5px solid #6868CE;
        display: flex;
        justify-content: center;
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #6868CE;
      }
    }

    .ant-modal-footer {
      .button {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 46px;

        div {
          width: 84px;
          height: 34px;
          border: 1px solid #6868CE;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 14px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #6868CE;
          cursor: pointer;
        }

        :nth-child(2) {
          background: url(../assets/beijing.png) no-repeat;
          background-size: cover;
          color: #fff;
          border: none;
          margin-left: 50px;
        }
      }
    }

    .ant-form-item {
      margin-bottom: 8px;
    }

    h3 {
      border-bottom: 1px solid #ccc;
      margin-bottom: 20px;
      padding: 10px;
      text-align: center;
    }

    .tips-box {
      font-size: 12px;
      color: #f5222d;
      padding-top: 14px;
      display: flex;
      i {
        margin-top: 8px;
        margin-right: 5px;
      }
    }

    .other-box .ant-form-explain {
      position: absolute;
    }
  }
}
</style>